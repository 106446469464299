import React from 'react'
import { useTranslation } from 'react-i18next'
import Social from '../social'
import MapCard from '../card/mapCard'
import PhoneIcon from '@material-ui/icons/Phone'
import MailOutlineIcon from '@material-ui/icons/MailOutline';

function Footer() {
    let id = 0
    function createData(day, start, finish) {
        id += 1
        return { id, day, start, finish }
    }

    const { t } = useTranslation('homepage')

    const rows = [
        // comment
        createData(t('mon'), '11:00', '19:00'),
        createData(t('tue'), '11:00', '19:00'),
        createData(t('wed'), '11:00', '19:00'),
        createData(t('thu'), '11:00', '19:00'),
        createData(t('fri'), '11:00', '19:00'),
        createData(t('sat'), '11:00', '19:00'),
        createData(t('sun'), '12:00', '18:00'),
    ]

    return (
        <>
            <footer id="site-footer" className="pt-3">
                <div className="container-full-width">
                    <div className="container-inner-xl">
                        <div className="contact-info grid-container">
                            <div className="first-columnt">
                                <div className="store-address">
                                    <h3>{t('visit_store')}</h3>
                                    <h4>{t('in_centre')}</h4>
                                    <p>20 Abbey Street Upper</p>
                                    <p>Dublin 1</p>
                                    <p>Ireland</p>
                                    <p>D01 DE00</p>
                                </div>
                            </div>
                            <div className="phone-and-email">
                                <div className="">
                                    <h3>{t('contact_us')}</h3>
                                    <div className="phone-and-email-item phone-number">
                                    <a href="tel:+353873936603">
                                    <p>
                                        <span className="contact-icon">
                                            <PhoneIcon />
                                        </span>{' '}
                                        <span className="contact-text">(+353) 087 393 6603</span>
                                    </p>
                                    </a>
                                    </div>
                                    <div className="phone-and-email-item email-address">
                                    <a href="mailto:info.proteinzone@gmail.com">
                                    <p>
                                        <span className="contact-icon">
                                            <MailOutlineIcon />
                                        </span>{' '}
                                        <span className="contact-text">info.proteinzone@gmail.com</span>  
                                    </p>
                                    </a>
                                    </div>
                                </div>
                                <div className="social-links">
                                    <h3>{t('find_us_on_social')}</h3>
                                    <Social />
                                </div>
                            </div>
                            <div className="opening-times">
                                <h3>{t('opening_times')}</h3>
                                <table>
                                    <tbody>
                                    {rows.map(row => (
                                        <tr key={row.id}>
                                            <td>{row.day}</td>
                                            <td>
                                                {row.start} — {row.finish}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="location-map">
                                <h3>{t('click_map')}</h3>
                                <MapCard />
                            </div>
                        </div>
                        <div className="copy-right center-font">
                            © {new Date().getFullYear()} Protein Zone
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
