import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import cx from 'clsx'


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    card: {
        background: 'none',
    },
    cardContent: {
        padding: '0',
            '&:last-child': {
                padding: '0',
            }
    },
    media: {
        height: 140,
    },
})


const MapCard = () => {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query {
            mapQuery: staticMap {
                childFile {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            linkQuery: staticMap {
                mapUrl
            }
            mapImage: staticMap {
                absolutePath
            }
        }
    `)

    return (
        <Card elevation={0} href={data.linkQuery.mapUrl} className={cx(classes.card, "map-card")}>
            <CardContent className={classes.cardContent}>
                <CardActionArea href={data.linkQuery.mapUrl}>
                    <CardMedia>
                        <Img fluid={data.mapQuery.childFile.childImageSharp.fluid} />
                    </CardMedia>
                </CardActionArea>
            </CardContent>
        </Card>
    ) 
}

export default MapCard
