import React from 'react'
import { navigate } from 'gatsby'
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next'
import Twemoji from "react-twemoji"
/* import { Menu, MenuItem, IconButton, Tooltip } from '@material-ui/core/' */
// import { Tabs, Tab } from '@material-ui/core/'


import { currentLanguages } from '../../i18n/config/currentLanguages'
import { usePageContext } from '../pageContext'

/* import Globe from '../../assets/svg/navIcons/globe.svg' */

function LanguagePicker() {
    const { originalPath } = usePageContext()
    const { i18n } = useTranslation()

    /* const [anchorEl, setAnchorEl] = React.useState(null) */

    const handleLangChange = lang => {
        i18n.changeLanguage(lang.shorthand)
        navigate(`${lang.path}${originalPath}`)
    }

    return (
        <>
            <nav id="language-switcher">
                <div id="language-switcher-content">
                    <ul className="nav lang-nav"
                        >
                        <Twemoji options={{ className: 'flag-emoji' }}>
                        {currentLanguages.map(lang => (
                            <li className="nav-item"
                                key={lang.shorthand}>
                                <Link
                                    to={`${lang.path}${originalPath}`}
                                    activeClassName="active"
                                    onClick={() => { handleLangChange(lang) }}
                                    >
                                    {lang.name}
                                </Link>
                            </li>
                        ))}
                        </Twemoji>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default LanguagePicker
