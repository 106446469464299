import React from 'react'
/* import LanguagePicker from '../languagePicker/languagePicker' */
import NavBar from './navBar'
import LanguagePickerTwo from '../languagePicker/languagePickerTwo'
import LanguagePicker from '../languagePicker/languagePicker'
import Drawer from '../drawer/drawer'
/* import Ieflag from '../languagePicker/flags' */
/* import Iesvg from '../../assets/flags/IE.svg' */
import Image from '../image'

import Logo from '../../assets/svg/logo.svg'

const Header = () => (
    <header>

        <NavBar />

        {/* <LanguagePickerTwo /> */}

{/*         <nav>
            <div className="navLHS">
                <Drawer />
            </div>
            <div className="navRHS">
                <LanguagePicker />
            </div>
        </nav>
 */}
        {/* <Image /> */}
        
    </header>
)

export default Header
