import React from 'react'
import { useTranslation } from 'react-i18next'
import NavLinks from '../../utils/navLinks'
import Link from '../link'

const NavWide = () => {

    const { t } = useTranslation()

    const wideNavContents = (
        <div
            role="presentation"
            className="nav-contents"
        >
            {NavLinks.map(navLink => (
                <Link to={navLink.path} key={navLink.id} className="nav-link">
                    {t(`${navLink.id}:title`)}
                </Link>
            ))}
        </div>
    )

    return (
        <>
        {wideNavContents}
        </>
    )
}

export default NavWide
