import React from 'react'
import PropTypes from 'prop-types'

import BgSlider from './backgroundSlider/backgroundSlider'
import Header from './header/header'

import Footer from './footer/footer'

const Layout = ({ children }) => {
    if (typeof window !== 'undefined') {
        // eslint-disable-next-line global-require
        require('smooth-scroll')('a[href*="#"]')
    }
    return (
        <>
            {/* <BgSlider /> */}
            <Header />
            <div className="content">
                <main>{children}</main>
                
                <Footer />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
