import React from 'react'

import LanguagePicker from '../languagePicker/languagePicker'
import LanguagePickerTwo from '../languagePicker/languagePickerTwo'
import NavWide from './navWide'
import Drawer from '../drawer/drawer'
import WideLogo from '../../assets/svg/logo-svg-optimal.svg'
import IconLogo from '../../assets/svg/logo-icon-svg-optimal.svg'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
    root: {},
    toolbarLang: {
        textAlign: 'center',
        flex: 0.8,
        minHeight: '40px',
    },
    toolbar: {
        flex: 0.8,
    },
    toolbarTwo: {
        backgroundColor: '#c92e3d',
        textAlign: 'center',
        flex: 0.8,
        minHeight: '50px',
    },
    divL: {
        flex: 1,
    },
    iconButton: {
        borderRadius: '5px',
    },
    divR: {
        flex: 1,
    },
    divSVGContainer: {
        maxWidth: '300px',
    },
}))

function NavBar() {

    const classes = useStyles()

    const screenLarge = useMediaQuery('(min-width:900px)')
    const screenMedium = useMediaQuery('(max-width:899px)')
    const selectLogoSize = () => {
        if (screenLarge) {
            return <WideLogo />
        }
        else if (screenSmall) {
            return <IconLogo />
        }        
    }
    const selectNavMode = () => {
        if (screenLarge) {
            return (
                <AppBar color="secondary" position="static">
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.divL}>{/* <Drawer /> */}</div>
                        <IconButton className={classes.iconButton} aria-label="menu" href="/">
                            <div className="svg-container">
                                <WideLogo />
                            </div>
                        </IconButton>
                        <div className={classes.divR}>
                            <LanguagePickerTwo />
                        </div>
                    </Toolbar>
                    <Toolbar className={classes.toolbarTwo}>
                        <NavWide />
                    </Toolbar>
                </AppBar>
            )
        } else if (screenMedium) {
            return (
                <AppBar color="secondary" position="static">
                    <Toolbar className={classes.toolbarLang}>
                        <div className={classes.divL}>
                            <Drawer />
                        </div>
                        <LanguagePickerTwo />
                        <div className={classes.divR}></div>
                    </Toolbar>
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.divL}></div>
                        <IconButton className={classes.iconButton} aria-label="menu" href="/">
                            <div className="svg-container">
                                <WideLogo />
                            </div>
                        </IconButton>
                        <div className={classes.divR}></div>
                    </Toolbar>
                </AppBar>
            )
        }
    }

    return (
        <>
        {selectNavMode()}
        </>
    )
}

export default NavBar
