import React from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, IconButton, Tooltip, Typography } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import Twemoji from 'react-twemoji'
import { currentLanguages } from '../../i18n/config/currentLanguages'
import { usePageContext } from '../pageContext'

import Globe from '../../assets/svg/navIcons/globe.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
    iconButton: {
        float: 'right',
        borderRadius: '5px',
    },
    langSpan: {
        paddingRight: '10px',
    },
}))

function LanguagePicker() {
    const classes = useStyles()
    const { originalPath } = usePageContext()
    const { i18n } = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleLangChange = lang => {
        handleCloseMenu()
        i18n.changeLanguage(lang.shorthand)
        navigate(`${lang.path}${originalPath}`)
    }

    return (
        <>
            <Tooltip
                title="Language"
                aria-label="Language"
                classes={{ popper: 'navPopper', tooltip: 'navTooltip' }}
            >
                <IconButton
                    className={classes.iconButton}
                    aria-haspopup="true"
                    aria-label="Language Selector"
                    aria-controls="lang-selector"
                    color="inherit"
                    onClick={handleOpenMenu}
                >
                    <Typography variant="body1" component="span" className={classes.langSpan}>
                        {i18n.language.toUpperCase()}
                    </Typography>
                    <Globe />
                    <ExpandMoreIcon />
                </IconButton>
            </Tooltip>
                <Menu
                    keepMounted
                    id="lang-selector"
                    disableAutoFocusItem
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    getContentAnchorEl={null}
                    onClose={handleCloseMenu}
                    classes={{ list: 'langSelectorOptions' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                <Twemoji options={{ className: 'flag-emoji' }}>
                    {currentLanguages.map(lang => (
                        <MenuItem
                            key={lang.shorthand}
                            classes={{ root: 'langSelectorItem' }}
                            data-test={`languagePicker-option-${lang.shorthand}`}
                            data-value={lang.path}
                            onClick={() => handleLangChange(lang)}
                        >
                            {lang.name.toUpperCase()}
                        </MenuItem>
                    ))}
                </Twemoji>
                </Menu>
        </>
    )
}

export default LanguagePicker
