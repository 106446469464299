const NavLinks = [
    {
        id: 'about',
        path: '#about-section',
    },
    {
        id: 'staff',
        path: '#staff-section',
    },
    {
        id: 'products',
        path: '#products-section',
    },
]

export default NavLinks
