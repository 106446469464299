import React from 'react'

import { ButtonGroup, IconButton, Button} from '@material-ui/core/'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'


function Social() {

    return (

        <div className="flexi-center">
            <ButtonGroup
                
                >
            <Button
                href="https://www.facebook.com/ProteinZone/"
                target="_blank"
                rel="noopener"
                color="inherit"
                >
                <FacebookIcon
                    style={{ fontSize: 30 }} />
            </Button>
            <Button
                href="https://www.instagram.com/proteinzone/"
                target="_blank"
                rel="noopener"
                color="inherit">
                <InstagramIcon
                    style={{ fontSize: 30 }} />
            </Button>
            <Button
                href="https://twitter.com/ProteinZone_IE"
                target="_blank"
                rel="noopener"
                color="inherit">
                <TwitterIcon
                    style={{ fontSize: 30 }}/>
            </Button>
            </ButtonGroup>
        </div>
    )
    
}

export default Social
